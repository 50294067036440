import * as React from 'react';
import Footer from '../../../../components/Footer';
import Title from '../../../../components/title/Title';
import Modal from '../../../../components/modal/Modal';
import { isClient, isWebRTCSupported } from '../../../../utils/browser';
import LayoutProvider from '../../../../components/layout/LayoutProvider';
import { Messages } from '../../../../utils';
import LiveViewer from '../../../../live/LiveViewer';

interface Props {
  location: any
}

class DemoViewerContainer extends React.Component<Props> {

  componentDidMount() {
    if(!isWebRTCSupported()) {
      alert("your browser does not support webrtc")
     };
  }

  public render() {
    const id : any = isClient && new URLSearchParams(window.location.search).get("id");
    
    return (
      <LayoutProvider location={this.props.location} className="demo-container" render={(innerWidth) => 
        <>
        <div className="content-container">
          <div className="demo-content row" style={{width: "960px"}}>
            <Title color="black" size="xlarge" className="montserrat-font" >
              Live stream
            </Title>
            <p className="landing-description montserrat-font" >{Messages.LIVE_DEMO_CONTENTS.description}</p>
            <div className="live-content" >
              <div className="live-title-group">
                <Title color="black" size="small" className="montserrat-font">Live Viewer</Title>
              </div>
              <div className="demo-viewer-container">
                <LiveViewer videoKey={id} />
              </div>
            </div>
            <div className="live-tool-group">
              <div className="input-group form-desc">
                {<div className="input-text"><small>{Messages.LIVE_DEMO_CONTENTS.liveDescription[0]}</small></div>}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Modal className="demo-modal" visible={false} onOk={() => {window.location.href = '/demo'}} disabled render={<p className="landing-description">{Messages.LIVE_DEMO_CONTENTS.endDesciption}</p>} title={"라이브 종료"} />
        </>
      } />
    )
  }
}

export default DemoViewerContainer;
